import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  const colorscheme = {
    links: "#923A56",
    bodyText: "#333",
    header: "#AA4465",
  }
  return {
    a: {
      boxShadow: `none`,
      color: colorscheme.links,
    },
    body: {
      color: colorscheme.bodyText,
    },
    h1: {
      fontFamily: ["PT Serif", "serif"].join(","),
      letterSpacing: "1px",
    },
    h2: {
      letterSpacing: "0.5px",
    },
    h3: {
      letterSpacing: "0.5px",
    },
  }
}

Wordpress2016.bodyFontFamily = ["Nunito", "sans-serif"]
Wordpress2016.headerFontFamily = ["PT Serif", "Georgia", "serif"]

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
